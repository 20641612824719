import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import ContainerView from '../../components/container';
import Toolbar from '../../components/toolbar';
import { useDeviceId } from '../../data/DeviceIdContext';
import { ScoreProvider, useScores } from '../../data/ScoreContext'; 
import { useNavigate,useLocation } from 'react-router-dom';
import { ArticlesProvider } from '../../data/ArticlesContext';
import SubTitleView from '../../components/subtitle';
import TitleView from '../../components/title';
import CardArticle from '../../components/card_article';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { motion } from 'framer-motion';
import VoteProgressBarVertical from '../../components/progress_var_vertical';
import VoteBarChart from '../../components/chart_container'
import VoteProgressBarHorizontal from '../../components/progress_var_horizontal';
import FooterLinkPaysika from '../../components/footer_link';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Suggestions from '../../components/suggestions';
 
// Enregistrer les composants utilisés pour que Chart.js fonctionne correctement
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


 // Styles pour centrer le graphique
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
 

const Resultat = () => {
  const location = useLocation();
  const receivedData = location.state?.data || "No data received";
  const { scores, fetchScore,fetchScores, loading } = useScores();
  const [stats, setStats] = useState(0);
  const [index, setIndex] = useState(0);
  const [states, setStates] = useState({ oui: 0, non: 0, neutre: 0 ,total:0});
  const colors = ['#009639','#FFD700','#002395',];
 // Récupérer les votes depuis le localStorage
  const storedVotes = JSON.parse(localStorage.getItem('userVotes')) || [];

  useEffect(() => {
    // Optionally re-fetch on component mount if needed
    console.log('receivedData : '+JSON.stringify(receivedData));
    if (receivedData) {
       fetchScore(receivedData);
    }else{
       fetchScores(receivedData);
      
    }

    console.log('storedVotes : '+JSON.stringify(storedVotes));
    // Compter les votes par opinion
    const voteCounts = { oui: 0, non: 0, neutre: 0,total:0 };
    storedVotes.forEach(vote => {
      if (vote.opinion === 'yes') voteCounts.oui++;
      if (vote.opinion === 'no') voteCounts.non++;
      if (vote.opinion === 'neutral') voteCounts.neutre++;
    });

    voteCounts.total = voteCounts.oui+voteCounts.non+voteCounts.neutre;

    // Définir les statistiques de votes
    setStates(voteCounts);

    if (scores) {
        const yes =scores.total_yes_count??0;
        const no = scores.total_no_count??0
        const neutral = scores.total_neutral_count??0;
        const total = scores.total_votes;
        setStats(total)

        let dataValues = [scores.total_yes_count??states.oui, scores.total_neutral_count??states.neutre, scores.total_no_count??states.non];
        // Trouver l'index de la valeur la plus élevée
        const maxIndex = dataValues.indexOf(Math.max(...dataValues));
        setIndex(maxIndex);
      }
  }, [receivedData]);



  const total = 40 + 20 + 160;
  const getHeightPercentage = (count) => (total === 0 ? 0 : (count / total) * 100);
  const [cards, setCards] = useState([
      { id: 1, size :1, title: 'Voter', text: 'Pour ce qui on déjà lu la nouvelle constitution', color: '#3A75C4',bg:'#3A75C44D' },
    ]);

   

  // Configuration des options du graphique
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((acc, value) => acc + value, 0);
            const value = context.raw;
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return `${context.label}: ${percentage}`;
          },
        },
        },
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Bar Chart Example',
      },
    
    // Plugin pour dessiner les pourcentages sur les barres
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          return ((value / total) * 100).toFixed(2) + '%';
        },
        color: '#000',
        font: {
          weight: 'bold',
        },
      },
      },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };


   const option = {
      plugins: {
          legend: {
        display: true, // Afficher la légende
        labels: {
          color: '#333', // Couleur du texte de la légende
          font: {
            size: 14, // Taille de la police
            weight: 'bold', // Style de la police
          },
          generateLabels: (chart) => {
            const dataset = chart.data.datasets[0];
            const data = dataset.data;

            // Trouver l'index de l'élément avec la valeur maximale
            const maxIndex = data.indexOf(Math.max(...data));

            // Retourner uniquement le label de cet élément avec sa couleur
            return [
              {
                text: `Majorité en-tête`,// chart.data.labels[maxIndex], // Le label correspondant
                fillStyle: dataset.backgroundColor[maxIndex], // Couleur associée
                hidden: !chart.isDatasetVisible(0), // Masquer ou afficher selon la visibilité
                datasetIndex: 0,
              },
            ];
          },
        },
      },

        tooltip: {
          callbacks: {
            label: (context) => {
              const total = context.dataset.data.reduce((acc, value) => acc + value, 0);
              const value = context.raw;
              const percentage = ((value / total) * 100).toFixed(2) + '%';
              return `${context.label}: ${percentage}`;
            },
          },
        },
        // Plugin pour dessiner les pourcentages sur les barres
        datalabels: {
          color: 'white', // Couleur du texte
          anchor: 'center', // Position verticale du texte
          align: 'center',  // Position horizontale du texte
          formatter: (value, context) => {
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            return ((value / total) * 100).toFixed(2) + '%';
          },
          color: '#000',
          font: {
            weight: 'bold',
            size: 12, // Taille de la police
          },
        },
      },
  };
  return  (
     <ScoreProvider>
      <PageWrapperView
          link={'/app'}
          view={
            <div>
              <ContainerView 
                view={
                  <>
                    <Toolbar/>
                    <TitleView title={'Resultat global'}/>
                    <SubTitleView title={'Vérifie ton match avec la constitution en quelques étapes'}/>
                   
                     <TotalContainer>
                        <TotalTitle>Bilan des votes d'articles</TotalTitle>
                        <TotalValue>{scores.total_votes??states.total}</TotalValue>
                      </TotalContainer>
                       <Suggestions resultat={true}/>
                   
                       <br/>
                          <Bar data={ {
                            labels: [`👍 Oui (${scores.total_yes_count??states.oui}) : ${((scores.total_yes_count / scores.total_votes) * 100).toFixed(2)} %`, `😐 Neutre (${scores.total_neutral_count??states.neutre}) : ${((scores.total_neutral_count / scores.total_votes) * 100).toFixed(2)} %`, `👎 Non (${scores.total_no_count??states.non}) : ${((scores.total_no_count / scores.total_votes) * 100).toFixed(2)} %`], // Exemple de labels
                            datasets: [
                              {
                                label: 'Majorité en-tête',
                                data: [scores.total_yes_count??states.oui, scores.total_neutral_count??states.neutre, scores.total_no_count??states.non],
                                backgroundColor: [
                                  '#009639',
                                  '#FFD700',
                                  '#002395',
                                ],
                                borderColor: colors, 
                                borderWidth: 1,
                                },
                              ],
                                }} 
                            options={option}>
                         </Bar>
                      <br/>
                      <FooterLinkPaysika/>
                      <br/>
                  </>
              }>
              </ContainerView>
            </div>
            }
        />
      </ScoreProvider>

  );
 
 
};
 

const TotalContainer = styled.div`
  background-color: #A8D5BA;
  border-radius: 20px;
  padding: 10px;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
`;

const TotalTitle = styled.h3`
  font-size: 18px;
  color: #555;
  align-items: center;
  justify-content: center;
`;

const TotalValue = styled.h1`
  font-size: 36px;
  color: #2867ac;
  align-items: center;
  justify-content: center;
`;


export default Resultat;
