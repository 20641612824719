import React from 'react';
import styled from 'styled-components';
import { PAYSIKA_LINK } from '../data/Constants';

const FooterLink = styled.a`
  color: #006837;
  font-weight: bold;
  text-decoration: none;
`;

const Footer = styled.footer`
  margin-bottom: 16px;
  font-size: 12px;
  color: #888;
  align-items: bottom;
  text-align: bottom;
`;

function FooterLinkPaysika() {
  return (
    <Footer>
          <p>Cette plateforme vous est proposée par: <FooterLink target="_blank" rel="noopener noreferrer" href={PAYSIKA_LINK}>PaySika</FooterLink></p>
    </Footer>
  );
}

export default FooterLinkPaysika;
