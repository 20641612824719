import "./error.scss";

/**
 * A default route to display when no other route has matched with the router.
 */
function ErrorPage() {
    return (
        <div className="route-not-found">Page indispobilble 404</div>
    );
}

export default ErrorPage;
