import { ArrowForward } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';



// Composant Styled pour le conteneur principal
const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

// Composant Styled pour chaque carte
const Card = styled.div`
  width: 150px;
  height: 180px;
  border-radius: 30px;
  background-color: #EBEBEB;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding:10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// Composant Styled pour le titre
const Title = styled.h3`
  font-size: 18px;
  color: #3A75C4;
`;

const Title1 = styled.h3`
  font-size: 18px;
  color: #388e3c;
`;

// Composant Styled pour le bouton
//   background-color: #4CAF50; /* couleur verte */
const Button = styled.button`
  align-self: flex-end;
  padding: 8px 12px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #3A75C4;
  }
`;

const Button1 = styled.button`
  align-self: flex-end;
  padding: 8px 12px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #45a049;
  }
`;

// Composant CardContainer
function CardContainer() {
  return (
    <Container>
        <Link to='/app/article'>
            <Card>
                <Title>Explorer les articles</Title>
                <ArrowForward style={{ color: '#3A75C4', alignSelf: 'flex-end',padding: '8px 12px' }}/> 
            </Card>
        </Link>
        <br/>
         <Link to='/'>
            <Card>
                <Title1>Voir les résultats</Title1>
                <ArrowForward style={{ color: '#388e3c', alignSelf: 'flex-end',padding: '8px 12px' }}/>
            </Card>
        </Link>
    </Container>
  );
}

export default CardContainer;
