import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar';
import { useSpring, animated } from '@react-spring/web';
import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const swipeRight = keyframes`
  0% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(150%); opacity: 0; }
`;

const swipeLeft = keyframes`
  0% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(-150%); opacity: 0; }
`;


const MobileContainer = styled.div`
  
    background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
 
const Description = styled.p`
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-bottom: 16px;
`;

// const Card = styled.div`
//   background-color: #4CAF50;
//   border-radius: 20px;
//   padding: 16px;
//   margin: 16px 0;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;
// `;

const CardContainer = styled.div`
  position: relative;
  height: 350px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 20px;
  padding: 16px;
  width: 90%;
  height: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  transform: translateY(${({ offset }) => offset}px) scale(${({ scale }) => scale});
  transition: all 0.3s ease;
`;

const Cards = styled.div`
  background-color: ${({ color }) => color || '#f8f8f8'};
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  min-height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: absolute;
  width: 90%;
  height:30%;
  left: 5%;
  top: ${({ index }) => index * 10}px;
  transition: transform 0.5s, opacity 0.5s;
  z-index: ${({ zIndex }) => zIndex};
  
  &.swipe-right {
    transform: translateX(100vw) rotate(10deg);
    opacity: 0;
  }

  &.swipe-left {
    transform: translateX(-100vw) rotate(-10deg);
    opacity: 0;
  }
`;
 

const CardTitle = styled.h3`
  font-size: 17px;
  color: #ffffff;
  margin-bottom: 8px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  border-radius: 20px;
  color: #4CAF50;
  font-weight: bold;
`;

const CardText = styled.p`
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  margin: 8px 0;
`;

const Button = styled.button`
  background-color: #D9EAD3;
  color: #4CAF50;
  padding: 12px 24px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 14px;
  color: #888;
`;

const FooterLink = styled.a`
  color: #006837;
  font-weight: bold;
  text-decoration: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

const OptionButton = styled.button`
  background-color: ${({ color }) => color || '#ccc'};
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

 

// Liste des couleurs représentant le drapeau gabonais
const COLORS = ['#009639', '#FFD700', '#002395']; // Vert, Jaune, Bleu
 

// Function to get the color based on the index
const getCardColor = (index) => {
  return COLORS[index % COLORS.length];
};

const Tinder = () => {

   
  const [swiped, setSwiped] = useState(false);

  const [articles, setArticles] = useState([
    { id: 1, title: 'Title 1', text: 'XX article à aléatoire pour avoir un avis sur la constitution', color: '#27A465' },
    { id: 2, title: 'Title 2', text: 'XX article a aleatoir de la constitution', color: '#FCD116' },
    { id: 3, title: 'Title 3', text: 'XX article a aléatoire avoir un avis complet sur la constitution', color: '#3A75C4' },
    // { id: 4, title: 'Title 4', text: 'XX article à aléatoire pour avoir un avis sur la constitution', color: '#27A465' },
    // { id: 5, title: 'Title 5', text: 'XX article a aleatoir de la constitution', color: '#FCD116' },
    // { id: 6, title: 'Title 6', text: 'XX article a aléatoire avoir un avis complet sur la constitution', color: '#3A75C4' },
    // { id: 7, title: 'Title 7', text: 'XX article à aléatoire pour avoir un avis sur la constitution', color: '#27A465' },
    // { id: 8, title: 'Title 8', text: 'XX article a aleatoir de la constitution', color: '#FCD116' },
    // { id: 9, title: 'Title 9', text: 'XX article a aléatoire avoir un avis complet sur la constitution', color: '#3A75C4' },
    // { id: 10, title: 'Title 10', text: 'XX article à aléatoire pour avoir un avis sur la constitution', color: '#27A465' },
    // { id: 11, title: 'Title 11', text: 'XX article a aleatoir de la constitution', color: '#FCD116' },
    // { id: 12, title: 'Title 12', text: 'XX article a aléatoire avoir un avis complet sur la constitution', color: '#3A75C4' },
  ]);


  const { x } = useSpring({
    x: swiped ? (onSwipe === 'right' ? 500 : -500) : 0,
    config: { tension: 200, friction: 20 },
    onRest: () => {
      if (swiped) {
        // Logic pour réagir après le swipe
        setSwiped(false); // Reset animation après le swipe
      }
    },
  });

    const [isVisible, setIsVisible] = useState(true);
  const [animation, setAnimation] = useState('');
 

  const [index, setIndex] = useState(0);
  const [swipeClass, setSwipeClass] = useState('');

  const handleSwipe = (direction) => {
    setSwipeClass(`swipe-${direction}`);
    setTimeout(() => {
      setSwipeClass('');
      setIndex((prevIndex) => (prevIndex + 1) % articles.length);
    }, 500);
  };


  return (
    <PageWrapperView 
      link={'/app'}
      view={
      <MobileContainer>
        <Toolbar/>
        
        <Description>Répondez au questions pour savoir si vous matchez ou pas avec la constitution</Description>
 

        <CardContainer>
          {articles.slice(index).map((article, index) => (
            <Card 
              key={index} 
              offset={index * 15} 
              scale={1 - index * 0.05}
              color={getCardColor(index)}
              style={{ zIndex: article.length - index }}
              zIndex={article.length - index}
              className={index === 0 ? swipeClass : ''}
            >
              <CardTitle>{article.title}</CardTitle>
              <CardText>{article.text}</CardText>
              <Button>Lire les détails</Button>
            </Card>
          ))}
        </CardContainer>
        <br/><br/>
        {/* {articles.map((article, index) => (
          <Card key={index}>
            <CardTitle>{article.title}</CardTitle>
            <CardText>{article.text}</CardText>
            <Button>Lire les détails</Button>
          </Card>
        ))} */}

        <ButtonContainer>
          <OptionButton onClick={() => handleSwipe('right')} color="#4CAF50">👍 Oui</OptionButton>
          <OptionButton color="#888">😊 Neutre</OptionButton>
          <OptionButton onClick={() => handleSwipe('left')} color="#E53935">❌ Non</OptionButton>
        </ButtonContainer>

        <Footer>
          <p>Lisez l'article et faites votre choix si dessus</p>
          <p>Cette plateforme vous est proposée par: <FooterLink href="#">PaySika</FooterLink></p>
        </Footer>
      </MobileContainer>
    }/>
  );
};

export default Tinder;
