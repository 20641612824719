import React, { useEffect } from 'react';
import styled from 'styled-components';
import GetStartedButton from './Button/get_start_btn';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import { ArrowForward, HelpOutline,ShareSharp } from '@mui/icons-material';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Fond gris */
  min-height: 100vh; /* Occupe toute la hauteur de l'écran */
`;

const MobileContainer = styled.div`
  width: 100%;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  min-height: 100vh;
  background-color: #ffffff; /* Fond blanc pour le contenu principal */
  padding: 16px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 16px',
  marginBottom: '30px',
});

const Logo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const LogoText = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#1d71b8',
});

const FooterButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  align-self: flex-end;
`;

const BottomBar = styled.div`
  background-color: #4caf50;
  width: 100%;
  height:50px;
  padding: 6px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomBarButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const BottomIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
  color:#fff;
`;

// Création d'un conteneur cliquable pour l'icône
// const ShareButton = styled.button`
//   background: none;
//   border: none;
//   padding: 0;
//   cursor: pointer;
//   display: inline-flex;
//   align-items: center;

//   &:hover ${ShareIcon} {
//     background-color: #0f63d1; // Changement de couleur au survol
//   }
// `;

//☰


function PageWrapperView({ view,link ,isFooter}) {

    const user = JSON.parse(localStorage.getItem('user')) || {};
  const isNotEmpty = Object.keys(user).length > 0;
  useEffect(() => {
      console.log('user : '+JSON.stringify(user));
    }, []);

    const handleShareWithImage = async () => {
  const imagePath = '/assets/image/share.png';

  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    const file = new File([blob], 'image.png', { type: 'image/jpeg' });

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      await navigator.share({
        title: 'Référendum‘24',
        text: "J'ai pris part au vote sur Référendum 2024, et maintenant c'est à toi ! Viens t'impliquer et fais entendre ta voix",
        files: [file],
        url: 'https://www.referendum2024.ga/',
      });
      console.log('Partage réussi !');
    } else {
      alert("Le partage de fichiers n'est pas pris en charge sur cet appareil.");
    }
  } catch (error) {
    console.error("Erreur lors de la récupération ou du partage de l'image :", error);
  }
};
let content;

 if (isNotEmpty) {
  content = <><ShareSharp onClick={handleShareWithImage} aria-label="share"></ShareSharp></>;
  }else{
    content = <></>;
  }

  return (
    <PageWrapper>
      {view}
     { isFooter==true? <></>:<>
      <BottomBar> 
        <GetStartedButton
            to={link}
            title="Accueil" 
            imageUrl="/assets/image/layers.png" 
          />
        <BottomIcon>{content}</BottomIcon>
      </BottomBar></>}
    </PageWrapper>
  );
}

export default PageWrapperView;

// function PageWrapperView() {
//   return (
//     <PageWrapper>
//       <MobileContainer>
//         <Header>
//           <Logo>Référendum‘24 🌟</Logo>
//           <IconButton>?</IconButton>
//         </Header>
//         <section>
//           <h2>Explorer les articles</h2>
//           <p>Vérifie ton match avec la constitution en quelques étapes</p>
//           <Card>
//             <CardTitle>Voter</CardTitle>
//             <CardText>Pour ce qui ont déjà lu la nouvelle constitution</CardText>
//             <FooterButton>Voir les résultats</FooterButton>
//           </Card>
//           <Card>
//             <CardTitle>Lire et voter</CardTitle>
//             <CardText>Votez chaque article et déterminez votre score de match avec la nouvelle constitution</CardText>
//             <FooterButton>Voir les résultats</FooterButton>
//           </Card>
//         </section>
//       </MobileContainer>
//     </PageWrapper>
//   );
// }
