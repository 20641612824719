import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar';
import SubTitleView from '../../components/subtitle';
import { useDeviceId } from '../../data/DeviceIdContext';
import axios from 'axios';
// import { useArticles } from '../../data/ArticlesContext';
import Button from '@mui/material/Button';
import LoaderView from '../../components/loader_view';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation } from 'react-router-dom';
import FooterLinkPaysika from '../../components/footer_link';



const Container = styled.div`
 background-color: #f5f5f5;
  padding: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const Label = styled.label`
  font-size: 1.8rem;
  margin-bottom: 5px;
`;

 

const Input = styled.input`
  padding: 12px 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 1rem;
  width: 100%;
`;

const Buttons = styled.button`
  padding: 10px;
  background-color: #34a853;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color:#c3c4d3;
  }
`;

 

function Forms() {
  const deviceId = useDeviceId();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const showErrorNotification = (message) => {
    toast.error(message);
  };

  const showInfoNotification = (message) => {
    toast.info(message);
  };

  const showNotification = () => {
    // toast.info("Merci d'avoir jouer le jeu!.");
    toast.success("Merci d'avoir joué le jeu ! Votre avis compte, et nous l'avons enregistré.");
  };
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Nom requis"; 
      else formErrors.name = '';;
    if (!formData.email) {
      formErrors.email = "Email requis";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email invalide";
    }else formErrors.email = '';
    if (!formData.phone) {
      formErrors.phone = "Numéro de téléphone requis";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = "Numéro de téléphone invalide";
    }else formErrors.phone = '';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmits = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('https://example.com/api/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          setSuccessMessage("Formulaire envoyé avec succès !");
          setFormData({ name: '', email: '', phone: '' });
          setErrors({});
        } else {
          setSuccessMessage("Erreur lors de l'envoi du formulaire.");
        }
      } catch (error) {
        console.error("Erreur de réseau :", error);
        setSuccessMessage("Erreur de réseau, veuillez réessayer.");
      }
    }
  };

  // return (
  //    <PageWrapperView 
  //     link={'/app'}
  //     view={
  //       <Container>
  //         <Toolbar/>

  //         <Label>Remplir le formulaire si dessous pour voir les résultat globaux</Label>

  //         {/* <subLabel>Vos informations sont sécurisées et anonymes</subLabel> */}
          
  //         {/* <small style={{ color: "#888" }}>Vos informations sont sécurisées et anonymes</small> */}
  //         <SubTitleView title={'Vos informations sont sécurisées et anonymes'}/>
          
  //         <Form onSubmit={handleSubmit}>
            
  //           <Input
  //             type="text"
  //             name="name"
  //             placeholder="Votre nom"
  //             value={formData.name}
  //             onChange={handleInputChange}
  //           />
  //           {errors.name && <small style={{ color: "red" }}>{errors.name}</small>}

  //           <Input
  //             type="email"
  //             name="email"
  //             placeholder="Entrer votre email"
  //             value={formData.email}
  //             onChange={handleInputChange}
  //           />
  //           {errors.email && <small style={{ color: "red" }}>{errors.email}</small>}

  //           <Input
  //             type="tel"
  //             name="phone"
  //             placeholder="Numero de telephone"
  //             value={formData.phone}
  //             onChange={handleInputChange}
  //           />
  //           {errors.phone && <small style={{ color: "red" }}>{errors.phone}</small>}

  //           <br/>
  //           <Button type="submit">Accepter</Button>
  //           {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
  //         </Form>
          
  //         <Footer>
  //           Cette plateforme vous est proposée par: <Link href="#">PaySika</Link>
  //         </Footer>

          
  //       </Container>
  //     }/>
  // );

  const [name, setName] = useState('');
  const [ville, setVille] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
   const [isValid, setIsValid] = useState(true);
    const gabonPhoneRegex = /^(?:\+241|00241)?[0-6]\d{7}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!navigator.onLine) {
      showErrorNotification("Vous n'avez pas de connexion internet.");
      setError("Vous n'avez pas de connexion internet.");
      setIsLoading(false);
      return;
    }

   const data = {
      "location":ville, 
      "email":email,
      "name":name,
      "phone_number":formatGabonPhoneNumber(phone)
    }

    const headers = {
      'Content-Type': 'application/json',
      'Device-ID': deviceId
    }

    try {
      const response = await axios.post('/contact', data,{headers: headers});
      if (response.status === 201) {
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/app/score-global');
      } else {
        if (response.status === 400) {
          console.log(response.data);
           showInfoNotification(response.data);
        }else
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (err) {
      setIsValid(gabonPhoneRegex.test(formatGabonPhoneNumber(phone)));
      // showInfoNotification(err);
      setError('Il semble que votre adresse électronique ou votre numéro de téléphone ait déjà été utilisé. Veuillez vérifier et réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

   useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      
      const userConfirmed = window.confirm("Voulez-vous nous quitter sans laisser votre contact ?");
      
      if (userConfirmed) {
        // Si l'utilisateur confirme, revenir en arrière
        navigate(-1);
      } else {
        // Sinon, reste sur la même page
        // window.history.pushState(null, '', window.location.href);
      }
    };

    // Empêche le retour immédiat en modifiant l'historique
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    // Nettoyage de l'écouteur pour éviter des fuites de mémoire
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

   const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    // Vérification du numéro avec le format gabonais
    setIsValid(gabonPhoneRegex.test(newPhone));
    setPhone(newPhone);

  };

  function formatGabonPhoneNumber(phoneNumber) {
  // Regex pour vérifier si le numéro contient déjà l'indicatif pays +241 ou 00241
  const hasCountryCode = /^(?:\+241|00241)/;

  // Ajoute l'indicatif si le numéro ne commence pas par +241 ou 00241
  if (!hasCountryCode.test(phoneNumber)) {
    return `+241${phoneNumber}`;
  }
  
  // Retourne le numéro tel quel s'il contient déjà l'indicatif
  return phoneNumber;
}


  // if (isLoading) return <p>Loading...</p>;

  return  (isLoading) ?<><Container><LoaderView /></Container></>  :   (
     <PageWrapperView 
       link={'/app'}
      view={
    <Container>
      <Toolbar/>
       <Label>Remplir le formulaire si dessous pour voir les résultat globaux</Label>
        <SubTitleView title={'Vos informations sont sécurisées et anonymes'}/>
        <br/>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Votre nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          type="email"
          placeholder="Entrer votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="tel"
          placeholder="Numéro de téléphone"
          value={phone}
          //  value={phone}
          onChange={handlePhoneChange}
          // onChange={(e) => setPhone(e.target.value)}
          required
        />
         <Input
          type="text"
          placeholder="Ville"
          value={ville}
          onChange={(e) => setVille(e.target.value)}
          required
        />
        <Buttons type="submit" disabled={isLoading}>
          {isLoading ? <LoaderView /> : 'Accepter'}
        </Buttons>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
       {!isValid && (
        <p style={{ color: 'red' }}>
          Veuillez entrer un numéro de téléphone gabonais valide.
        </p>
      )}
      <br/>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <FooterLinkPaysika/>
       
    </Container>
    }/>
  );

}

export default Forms;
