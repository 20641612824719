import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import GetStartedButton from '../../components/Button/get_start_btn';
import PageWrapperView from '../../components/wrapper_page';
import { useDeviceId } from '../../data/DeviceIdContext';
import FooterLinkPaysika from '../../components/footer_link';
import { PAYSIKA_LINK } from '../../data/Constants';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Fond gris */
  min-height: 100vh; /* Occupe toute la hauteur de l'écran */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  width: 100%;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
  background: linear-gradient(to bottom, #2c80d0, #f7dd44,#009E60);
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
`;

const Title = styled.h1`
  font-size: 2.8em;
  font-weight: bold;
  margin: 0;
  margin-top: 60px;
  color: white;
`;

const Subtitle = styled.h3`
  font-size: 1.2em;
  font-weight: normal;
  margin: 0;
  margin-top: 10px;
  color: white;
`;

const Description = styled.p`
  font-size: 2.3em;
  font-weight: bold;
  margin-top: 60px;
  color: white;
  text-align: left;
`;

const Btn = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: flex-start;  
  align-items: center; 
`;

const FooterLink = styled.a`
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
`;

const Footer = styled.footer`
  margin-bottom: 16px;
  font-size: 12px;
  color: #ffffff;
  align-items: bottom;
  text-align: bottom;
`;

// const useUniqueDeviceIdentifier = () => {
//   const [deviceId, setDeviceId] = useState(null);

//   useEffect(() => {
//     // Vérifie si un identifiant existe déjà
//     let storedDeviceId = localStorage.getItem('deviceId');
//     if (storedDeviceId) {
//       // Génère un nouvel identifiant unique pour cet appareil
//       const userAgent = navigator.userAgent.replace(/[\s\-();.,/]+/g, ''); // Remplacer les espaces pour éviter les erreurs
//       const date = new Date().getTime(); // Timestamp pour une précision temporelle
//       storedDeviceId = `device${userAgent}${date}`;
//       // storedDeviceId = `${navigator.userAgent}-${new Date().getTime()}`;
//       localStorage.setItem('deviceId', storedDeviceId);
//     }
//     setDeviceId(storedDeviceId);
//   }, []);

//   return deviceId;
// };

const Splash = () => {
   const deviceId = useDeviceId();
   const time =  localStorage.getItem('time');

   useEffect(() => {
    if (deviceId) {
      // Charge les articles depuis l'API
      console.log('deviceId : '+deviceId)
      // fetchArticles();
    }
  }, [deviceId]);

  return (
    <PageWrapper>
        <Container>
          <Header>
            <Title>Bienvenue au</Title>
            <Subtitle>Référendum 2024 🌟</Subtitle>
            <Description>Une plateforme sécurisée et anonyme pour savoir si tu matches avec la nouvelle constitution du Gabon</Description>
          </Header>
          <br/>
            <Footer>
                  <p>Cette plateforme vous est proposée par: <FooterLink target="_blank" rel="noopener noreferrer" href={PAYSIKA_LINK}>PaySika</FooterLink></p>
            </Footer>
          <br/>
          <Btn>
             
              <GetStartedButton
                to="/a-propos"
                title="Suivant" 
                imageUrl="/assets/image/layers.png" 
              />
          </Btn>  
        </Container>
    </PageWrapper>
  );
};

export default Splash;
