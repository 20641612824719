import React from 'react';
import styled from 'styled-components'; 
 

// Composants stylisés pour la mise en page
const Container = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

function ContainerView({ view,link }) {
  return (
      <Container>
        {view}
      </Container>
  );
}

export default ContainerView;
