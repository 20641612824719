import React, { createContext, useContext, useState, useEffect } from 'react';
import model from '../models/articles.js'
import axios from 'axios';
import { useDeviceId } from './DeviceIdContext.js';
import { API_URL } from './Constants.js';



const ArticlesContext = createContext();

export const useArticles = () => useContext(ArticlesContext);
 
export const ArticlesProvider = ({ children }) => {
  const deviceId = useDeviceId();
  const [error, setError] = useState('');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const articlesStored = JSON.parse(localStorage.getItem('articles')) || [];
  

  const fetchArticles = async (limit = 150) => {
      console.log('limit ',limit);
    // if (articlesStored.length > 1) {
    //   console.log('Local article > 1 ');//+JSON.stringify(articlesStored)
    //   const formattedArticles = articlesStored.slice(0, limit).map(article => ({
    //         id : article.id,
    //         number : article.number,
    //         title : article.title,
    //         content : article.content,
    //         color :article. color,
    //     }));
    //     setArticles(formattedArticles);
    // }{
      const headers = {
        'Content-Type': 'application/json',
        'Device-ID': deviceId
      }

     try {
      setLoading(true);
      // const responses = await fetch(`${API_URL}/articles`);
      // const data = await responses.json();
      // console.log('Local article ',responses)
      const response = await axios.get('/articles',{headers: headers});
      console.log('fetchArticles ',response)
      if (response.status === 200) {
        if (response.data) {
          if (limit >= 150) {
             const formattedArticles = response.data.map(article => ({
              id : article.id,
              number : article.number,
              title : article.title,
              content : article.content,
              color :article. color,
            }));
            
            setArticles(formattedArticles);
            localStorage.setItem('articles', JSON.stringify(formattedArticles));
            // console.log('limit > 150 ',limit);
            // console.log('setArticles > 150 ',formattedArticles.length);
          }else{
            // console.log('limit < 150 ',limit);
             const formattedArticles = response.data.slice(0, limit).map(article => ({
                id : article.id,
                number : article.number,
                title : article.title,
                content : article.content,
                color :article. color,
            }));
            setArticles(formattedArticles);
            localStorage.setItem('articles', JSON.stringify(formattedArticles));
          }
        // console.log(JSON.stringify(formattedArticles))
      }
      } else {
        console.error("Une erreur est survenue. Veuillez réessayer.");
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (err) {
      console.error("Failed to fetch articles:", error);
      setError('Erreur de serveur. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    } 
    // try {
    //   setLoading(true);
    //   const response = await fetch(`${API_URL}/articles`);
    //   const data = await response.json();
    // //  const formattedArticles = data.map(article => new model(userId, article.id));
    //   if (data) {
    //     const formattedArticles = data.slice(0, limit).map(article => ({
    //         id : article.id,
    //         number : article.number,
    //         title : article.title,
    //         content : article.content,
    //         color :article. color,
    //     }));
    //     setArticles(formattedArticles);
    //     localStorage.setItem('articles', JSON.stringify(formattedArticles));
    //     // console.log(JSON.stringify(formattedArticles))
        
    //   }
    // } catch (error) {
    //   console.error("Failed to fetch articles:", error);
    // } finally {
    //   setLoading(false);
    // }
  // }
  };

   const fetchArticlesOnly = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Device-ID': deviceId
      }
     try {
      setLoading(true); 
      const response = await axios.get('/articles',{headers: headers});
      // console.log('fetchArticlesOnly ',response)
      if (response.status === 200) {
          if (response.data) {
              const formattedArticles = response.data.map(article => ({
                id : article.id,
                number : article.number,
                title : article.title,
                content : article.content,
                color :article. color,
            }));
            setArticles(formattedArticles);
            // console.log('fetchArticlesOnly lenght ',formattedArticles.length);
            localStorage.setItem('articles', JSON.stringify(formattedArticles));
        }
      } else {
        console.error("Une erreur est survenue. Veuillez réessayer.");
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (err) {
      console.error("Failed to fetch articles:", error);
      setError('Erreur de serveur. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    } 
  
  };

   const fetchArticlesLocaly = async () => {
    //  const limit =  localStorage.getItem('limit');
    const savedInteger = localStorage.getItem('limit');
    const limit = savedInteger !== null ? parseInt(savedInteger, 10) : null;
    console.log('Local article >= limit :',limit);//+JSON.stringify(articlesStored)
    const formattedArticles = articlesStored.slice(0, limit).map(article => ({
          id : article.id,
          number : article.number,
          title : article.title,
          content : article.content,
          color :article. color,
      }));
    setArticles(formattedArticles);
    
  };

  useEffect(() => {
    fetchArticles();
    // localStorage.clear();
  }, []);

  return (
    <ArticlesContext.Provider value={{ articles, fetchArticles,fetchArticlesLocaly,fetchArticlesOnly, loading }}>
      {children}
    </ArticlesContext.Provider>
  );
};
/** const headers = {
      'Content-Type': 'application/json',
      'Device-ID': deviceId
    }


     try {
      setLoading(true);
      const response = await axios.get('/articles',{headers: headers});
      if (response.status === 200) {
        if (response.data) {
        const formattedArticles = response.data.slice(0, limit).map(article => ({
            id : article.id,
            number : article.number,
            title : article.title,
            content : article.content,
            color :article. color,
        }));
        setArticles(formattedArticles);
        localStorage.setItem('articles', JSON.stringify(formattedArticles));
        // console.log(JSON.stringify(formattedArticles))
        
      }
      } else {
        console.error("Une erreur est survenue. Veuillez réessayer.");
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (err) {
      console.error("Failed to fetch articles:", error);
      setError('Erreur de serveur. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    } */