import React, { createContext, useContext, useState, useEffect } from 'react';
import model from '../models/articles.js'
import { API_URL } from './Constants.js';
import { useDeviceId } from './DeviceIdContext.js';


const ScoreContext = createContext();

export const useScores = () => useContext(ScoreContext);


export const ScoreProvider = ({ children }) => {
  const [scores, setScores] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const deviceId = useDeviceId();


   const fetchScore = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Device-ID': deviceId
      }
     try {
      setLoading(true); 
      const stats = await fetch(`${API_URL}/statistics`);
      const data = await stats.json();
      console.log('stats ',data)

       if(data != {}) {
         const fetchScore =  {
            total_yes_count: data.total_yes_count,
            total_no_count: data.total_no_count,
            total_neutral_count: data.total_neutral_count,
            total_votes:data.total_votes,
            // articles:model.articles.map(article => ({
            //     id : article.id,
            //     number : article.number,
            //     title : article.title,
            //     content : article.content,
            //     color :article. color,
            // }))
          };
          setScores(fetchScore);
        }
        console.log('fetchScore ',data.total_votes)

      // const response = await axios.get('/statistics',{headers: headers});
      // console.log('statistics ',response)
      
      // if (response.status === 200) {
      //     if (response.data) {
      //          const fetchScore = response.data.map(model => ({
      //           total_yes_count: model.total_yes_count,
      //           total_no_count: model.total_no_count,
      //           total_neutral_count: model.total_neutral_count,
      //           total_votes:model.total_votes,
      //           // articles:model.articles.map(article => ({
      //           //     id : article.id,
      //           //     number : article.number,
      //           //     title : article.title,
      //           //     content : article.content,
      //           //     color :article. color,
      //           // }))
      //       }));
      //       setScores(fetchScore);
      //   }
      // } else {
      //   console.error("Une erreur est survenue. Veuillez réessayer.");
      //   setError('Une erreur est survenue. Veuillez réessayer.');
      // }
    } catch (err) {
      console.error("Failed to fetch fetchScore:", error);
      setError('Erreur de serveur. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    } 
  
  };

  const fetchScores = async (limit = 10) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/stats`);
      const data = await response.json();
      if (data) {
        const formattedArticles = data.map(model => ({
            total_yes_count: model.yes_count,
            total_no_count: model.no_count,
            total_neutral_count: model.neutral_count,
            total_vote:model.total_vote,
            articles:model.vote.map(article => ({
                id : article.id,
                number : article.number,
                title : article.title,
                content : article.content,
                color :article. color,
            }))
        }));
        setScores(formattedArticles);
        // console.log(JSON.stringify(formattedArticles))
      }
    } catch (error) {
      console.error("Failed to fetch fetchScores:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScore();
  }, []);

  return (
    <ScoreContext.Provider value={{ scores, fetchScore, loading }}>
      {children}
    </ScoreContext.Provider>
  );
};
/*  const fetchScoreOnly = async (limit = 10) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/statistics`);
      const data = await response.json();
      if (data) {
        console.log('Data valide : ',JSON.stringify(data))
        const fetchScore = data.map(model => ({
            total_yes_count: model.total_yes_count,
            total_no_count: model.total_no_count,
            total_neutral_count: model.total_neutral_count,
            total_votes:model.total_votes,
            // articles:model.articles.map(article => ({
            //     id : article.id,
            //     number : article.number,
            //     title : article.title,
            //     content : article.content,
            //     color :article. color,
            // }))
        }));
        setScores(fetchScore);
        // console.log(JSON.stringify(formattedArticles))
      }
    } catch (error) {
      console.error("Failed to fetch fetchScore:", error);
    } finally {
      setLoading(false);
    }
  }; */