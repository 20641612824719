import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar'; 
import { useNavigate,useLocation } from 'react-router-dom';
import { useDeviceId } from '../../data/DeviceIdContext';
import { useArticles } from '../../data/ArticlesContext';
import LoaderView from '../../components/loader_view'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PAYSIKA_LINK } from '../../data/Constants';
import { motion } from 'framer-motion';
import TitleView from '../../components/title';
import FooterLinkPaysika from '../../components/footer_link';



const CardViewContainer = styled.div` 
  position: relative;
  height: 350px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
 
// const MobileContainer = styled.div`
//   width: 100%;
//   max-width: 428px; /* Largeur type pour un téléphone mobile */
//   background-color: #ffffff; /* Fond blanc pour le contenu principal */
//   padding: 16px;
//   box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
//   position: relative;
//   min-height: 100vh;
//   overflow: hidden; /* Pour éviter le débordement des cartes */
// `;

const FooterLink = styled.a`
  color: #006837;
  font-weight: bold;
  text-decoration: none;
`;

const MobileContainer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;
 

const Description = styled.p`
  font-size: 18px;
  color: #888;
`;
const SubDescription = styled.p`
  font-size: 14px;
  color: #888;
`;

// const Card = styled.div`
//   background-color: ${({ color }) => color || '#f8f8f8'};
//   border-radius: 20px;
//   padding: 16px;
//   margin: 1px -8px; 
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;
//   position: absolute;
//   width: 90%;
//   height: 85%;
//   left: 3%;
//   right: 4%;
//   top: ${({ index }) => index}px;
//   transition: transform 0.6s, opacity 0.5s;
//   z-index: ${({ zIndex }) => zIndex};
//   &.swipe-right {
//     transform: translateX(100vw) rotate(10deg);
//     opacity: 0;
//   }

//   &.swipe-left {
//     transform: translateX(-100vw) rotate(-10deg);
//     opacity: 0;
//   }
// `;

const Card = styled.div`
  background-color: ${({ color }) => color || '#f8f8f8'};
  border-radius: 20px;
  padding: 16px;
  margin: 1px -8px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  width: 90%;
  height: ${({ expanded }) => (!expanded ? '85%' : '65vh')}; /* Change height based on expanded state */
  left: 3%;
  right: 4%;
  top: ${({ index }) => index}px;
  transition: height 0.5s ease, transform 0.6s, opacity 0.5s;
  z-index: ${({ zIndex }) => zIndex};

  &.swipe-right {
    transform: translateX(100vw) rotate(10deg);
    opacity: 0;
  }

  &.swipe-left {
    transform: translateX(-100vw) rotate(-10deg);
    opacity: 0;
  }
`;
const Content = styled.div`
  max-height: ${({ expanded }) => (!expanded ? '85%' : '65vh')}; /* Max height for expanded content */
  overflow: hidden;
  transition: max-height 0.5s ease;
`;
const CardTexts = styled.p`
  font-size: 18px;
  color: ${({ color }) => color || '#3A75C4'};
  text-align: center;
  margin: 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cards = styled.div`
  background-color: ${({ color }) => color || '#f8f8f8'};
  border-radius: 20px;
  padding: 16px;
  margin: 1px -8px; 
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  width: 90%;
  height: 75%;
  left: 3%;
  right: 4%;
`;
 
 
const CardTitle = styled.h3`
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 8px;
  padding: 8px 8px;
  background-color: #FFFFFF;
  border-radius: 20px;
  color: #4CAF50;
  text-align: center;
  font-weight: bold;
`;

// const CardText = styled.p`
//   font-size: 14px;
//   color: #FFFFFF;
//   text-align: center;
//   margin: 8px 0;
//   display: -webkit-box;
//   -webkit-line-clamp: 10;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

// const CardText = styled.p`
//   font-size: 14px;
//   color: #FFFFFF;
//   text-align: center;
//   margin: 8px 0;
//   display: -webkit-box;
//   -webkit-line-clamp: ${({ expanded }) => (expanded ? 'unset' : '10')};
//   -webkit-box-orient: vertical;
//   overflow: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
//   text-overflow: ${({ expanded }) => (expanded ? 'unset' : 'ellipsis')};
// `;

const CardText = styled.p`
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  margin: 8px 0;
  overflow-y: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  text-overflow: ${({ expanded }) => (expanded ? 'unset' : 'ellipsis')};
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? 'unset' : '10')};
  -webkit-box-orient: vertical;
  max-height: ${({ expanded }) => (expanded ? '65vh' : 'auto')}; /* ajustez la hauteur max selon vos besoins */
  /* Masquer la barre de défilement dans Chrome, Safari et Edge */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  /* Masquer la barre de défilement dans Firefox */
  scrollbar-width: none; /* Firefox */
`;

const Button = styled.button`
  background-color: #D9EAD3;
  color: #4CAF50;
  padding: 8px 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;
 
 
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

const OptionButton = styled.button`
  background-color: ${({ color }) => color || '#ccc'};
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #34a853;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: spin 1s linear infinite;
  margin-top: 20px; 
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
 
// Liste des couleurs représentant le drapeau gabonais
const COLORS = ['#009639', '#FFD700', '#002395']; // Vert, Jaune, Bleu
 

// Function to get the color based on the index
const getCardColor = (index) => {
  return COLORS[index % COLORS.length];
};

function Match({limit}) {
  const deviceId = useDeviceId();
  // const articles =  useArticles(); 
  const navigate = useNavigate();
   const location = useLocation();
  const receivedData = location.state?.data || "No data received";
  const notify = (message) => toast(message);
  const { articles, fetchArticles,fetchArticlesLocaly, loading } = useArticles();
  const [canNext, setCanNext] = useState(false);
  const showErrorNotification = () => {
    toast.error("Veuillez patiente que les articles s'affichent.");
  };

  const showNotification = () => {
    toast.info("Merci d'avoir jouer le jeu!.");
    toast.success("Merci d'avoir jouer le jeu!.");
  };

  useEffect(() => { 
    console.log('receivedData : '+JSON.stringify(receivedData));
   if(receivedData != 'No data received'){ 
    localStorage.setItem('limit',receivedData);
    fetchArticles(receivedData);
  }else{
    fetchArticlesLocaly();
  }
  }, []);
  


const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
const [userVotes, setUserVotes] = useState([]);
const [swipeClass, setSwipeClass] = useState('');

useEffect(() => {
  if (currentArticleIndex === articles.length && canNext) {
    // Enregistre dans localStorage une fois que tous les articles sont votés
    if (userVotes.length > 1) {
       localStorage.setItem('userVotes', JSON.stringify(userVotes));
       navigate('/app/score');
    }else{
      navigate('/app/score');
    }
  }
}, [userVotes, currentArticleIndex, articles.length, navigate]);

const handleSwipe = (direction, opinion) => {
  if (articles.length >= 1) {
    const currentArticle = articles[currentArticleIndex];
    const vote = {
      user_id: 0, // deviceId
      article_id: currentArticle.id,
      opinion: opinion,
    };

    setSwipeClass(`swipe-${direction}`);
    setUserVotes((prevVotes) => [...prevVotes, vote]); 

    setTimeout(() => {
      setSwipeClass('');
      setCurrentArticleIndex((prevIndex) => prevIndex + 1);
      setCanNext(true);
    }, 500);
  } else {
    showErrorNotification();
  }
};

 useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      
      const userConfirmed = window.confirm("Voulez-vous vraiment arrêter le match ?");
      
      if (userConfirmed) {
        // Si l'utilisateur confirme, revenir en arrière
        navigate('/app');
      } else {
        // Sinon, reste sur la même page
        // window.history.pushState(null, '', window.location.href);
      }
    };

    // Empêche le retour immédiat en modifiant l'historique
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    // Nettoyage de l'écouteur pour éviter des fuites de mémoire
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return  loading ? <><MobileContainer><LoaderView /></MobileContainer></> :  (
     <PageWrapperView 
      link={'/app'}
      view={
      <MobileContainer>
        <Toolbar/>
        
        <Description>{receivedData==1?"Voter sans lire":"Répondez aux questions pour savoir si vous matchez ou pas avec la constitution."}</Description>
        <SubDescription>{receivedData==1?"Pour ce qui on déjà lu la nouvelle constitution constitution":""}</SubDescription>
         {/* <br/> */}
       
        <CardViewContainer>
          {receivedData==1?<Cards
               key={1}
               color={'#CFCFCF4D'}
               index={1}
               offset={1 * 15} 
               scale={1 * 0.05}
               zIndex={1}
               className={ ''}>
                 <CardTexts color={'#3A75C4'}>Etes vous pour ou contre la constitution?</CardTexts>
             </Cards> :
           <> {articles != 0? articles.slice(currentArticleIndex).map((card, i) => (
            <Card
              key={card.id}
              color={card.color}
              // color={getCardColor(index)}
              index={receivedData>=90?i * 0.4:receivedData>=60?i * 0.3: i*0.8}
              offset={currentArticleIndex * 15} 
              scale={1 - currentArticleIndex * 0.05}
              zIndex={articles.length - i}
              className={i === 0 ? swipeClass : ''}
              expanded={expanded}>
                <CardTitle>{`(${currentArticleIndex}) `}{card.title}</CardTitle>
                <Content expanded={expanded}>
                  <CardText expanded={expanded}>{card.content.replace(/[-]+/g, '')}</CardText>
                </Content>
                <Button onClick={toggleExpand}>
                   {expanded ? 'Voir moins' : 'Lire les détails'}
                   </Button> 
            </Card>
        )) :
        <Loader />}</>
          }
           
        </CardViewContainer> 

      {expanded?'' : <> {articles.length >= 150?<><br/><br/><br/></>:articles.length >= 90?<><br/><br/></>:articles.length >= 60?<><br/></>: <></>}
        <p>Lisez l'article et faites votre choix ci-dessous</p>
        
            <ButtonContainer>
              <OptionButton onClick={() => handleSwipe('left','no')} color="#E53935">👎 Non</OptionButton>
              <OptionButton onClick={() => handleSwipe('top','neutral')} color="#888">😊 Neutre</OptionButton>
              <OptionButton onClick={() => handleSwipe('right','yes')} color="#4CAF50">👍 Oui</OptionButton>
            </ButtonContainer>
            </> 
            }
       
        <br/>

        <FooterLinkPaysika/>
         <br/>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </MobileContainer>
    }/>
  );
}

export default Match;

 /**  <Card
               key={1}
               color={'#FFFEFEF'}
               index={i}
               offset={1 * 15} 
               scale={1 * 0.05}
               zIndex={1}
               className={ ''}
             >
                 <Loader />
                 <CardText></CardText>
               
             </Card> } */