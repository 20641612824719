import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import ContainerView from '../../components/container';
import { useNavigate,useLocation } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';


// Styles pour la page
const PageContainer = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: column;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;
 
const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: #0073e6;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
  &:hover {
    text-decoration: underline;
  }
`;


const CEOImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
`;

const Name = styled.h2`
  font-size: 24px;
  color: #333;
  margin: 16px 0 8px;
`;

const Title = styled.h4`
  font-size: 18px;
  color: #555;
  margin-bottom: 16px;
`;

const Bio = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  text-align: left;
  /* Masquer la barre de défilement dans Chrome, Safari et Edge */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  /* Masquer la barre de défilement dans Firefox */
  scrollbar-width: none; /* Firefox */
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;
`;

const SocialLink = styled.a`
  color: #0073e6;
  font-size: 20px;
  transition: color 0.3s;

  &:hover {
    color: #005bb5;
  }
`;

const NavigationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #009639;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #006b3c;
  }
`;

const Signature = styled.h2`
  font-size: 16px;
  color: #333;
  margin-top: 40px;
  text-align: left;
  line-height: 1.5;
  margin: 16px 0 8px;
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  padding:10px;
  left: 20px;
  display: flex;
  align-items: left;
  background-color: while;
  color: #111111;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  &:hover {
    background-color: while;
  }
`;

// Composant de la page
const AboutCEO = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    const bioText = `
        <p>Aujourd'hui, le téléphone est devenu l'outil principal des jeunes. En moyenne, un jeune passe environ <strong>7 heures par jour</strong> sur son téléphone. Cette utilisation intensive est une conséquence directe des nouvelles technologies, des innovations constantes et des nombreuses applications qui sortent quotidiennement. Ces outils numériques façonnent non seulement les comportements, mais influencent également les décisions et modes de vie des jeunes. Cependant, toutes ces innovations ne sont pas bénéfiques.</p>
        <p>Certaines applications, malgré leur popularité, peuvent être <strong>néfastes pour la jeunesse</strong>, contribuant à la perte de temps, à l’isolement social, ou à des effets négatifs sur la santé mentale à travers des contenus inappropriés ou addictifs. Leurs algorithmes sont souvent conçus pour captiver l'attention des utilisateurs de manière excessive, créant des cycles de distraction qui nuisent à la productivité et au bien-être des jeunes.</p>
        <p>C'est dans ce contexte que <strong>les entrepreneurs, soucieux du développement de la jeunesse</strong>, ont une responsabilité particulière. Ils doivent exploiter ces mêmes technologies pour créer des outils qui favorisent <strong>l'émancipation, l'autonomie, et l'engagement</strong> des jeunes. En effet, plutôt que d’être spectateurs passifs de contenus souvent inutiles ou dangereux, les jeunes doivent avoir accès, via leur téléphone, à <strong>des applications positives</strong>, conçues pour les aider à grandir, à se former, et à s'investir dans des projets qui améliorent leur vie et leur société.</p>
        <p>C'est exactement ce que PaySika s'efforce de faire aujourd'hui. En tant qu'entrepreneurs engagés, nous avons conçu des solutions qui vont bien au-delà du simple usage quotidien du téléphone. Nous donnons aux jeunes des outils financiers simples, innovants, et accessibles directement depuis leur téléphone, comme l'<strong>obtention gratuite d'une carte bancaire</strong>. Cela leur permet de gérer leur argent, de planifier leur avenir financier et de prendre le contrôle de leurs ressources, renforçant ainsi leur autonomie.</p>
        <p>Nous croyons fermement que la <strong>technologie</strong> doit être un levier <strong>d'émancipation</strong> pour les jeunes, et non un obstacle. C’est pourquoi nous créons des solutions qui sont à la fois utiles et orientées vers le progrès. <strong>PaySika</strong> ne se limite pas à être une simple application financière ; c’est un partenaire de la jeunesse, qui les accompagne dans leur quotidien et les aide à devenir des acteurs de leur propre avenir.</p>
        <p><strong>L’objectif est clair</strong> : utiliser la <strong>puissance des nouvelles technologies</strong> pour redonner aux jeunes les moyens de se réaliser, de prendre des décisions éclairées, et de participer activement au développement de leur communauté et de leur pays.
  `;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNext = () => {
      localStorage.setItem('time', 'true');
      navigate('/app');
  };


  return (
    <PageWrapperView
        link={'/app'}
        isFooter={true}
        view={
            <PageContainer>
            <Name>Mot du Dirigeant</Name>
            <Bio dangerouslySetInnerHTML={{ __html: isExpanded ? bioText : `${bioText.slice(0, bioText.length / 5)}...` }} />
            <ShowMoreButton onClick={toggleExpand}>
                {isExpanded ? 'Voir moins' : 'Voir plus'}
            </ShowMoreButton>
            {/* <Bio>
                {isExpanded ? bioText : `${bioText.slice(0, bioText.length / 4)}...`}
                <ShowMoreButton onClick={toggleExpand}>
                {isExpanded ? 'Voir moins' : 'Voir plus'}
                </ShowMoreButton>
            </Bio> */}
            <br/>
            <Signature>
                Stezen BISSELOU-NZENGUE,<br />
                Cofondateur et Directeur Général de PaySika Holding<br />
                Manager International en Technologies et Innovations<br />
                Ingénieur en Robotique et Intelligence Artificielle
            </Signature>
            <br/>
            {/* <NavigationButton onClick={handleNext}>
                {"Explorer les articles de la constitution"}
            </NavigationButton> */}
            <br/>
            <br/>
            <br/>
            {/* <SocialLinks>
                <SocialLink href="https://linkedin.com" target="_blank" aria-label="LinkedIn">
                🔗 LinkedIn
                </SocialLink>
                <SocialLink href="https://twitter.com" target="_blank" aria-label="Twitter">
                🐦 Twitter
                </SocialLink>
                <SocialLink href="https://facebook.com" target="_blank" aria-label="Facebook">
                📘 Facebook
                </SocialLink>
            </SocialLinks> */}
            <ToggleButton onClick={handleNext}>
                <FaArrowRight style={{ marginLeft: '8px',marginRight: '8px' ,color:'#111111' }} />
                 { "Commencer"}
            </ToggleButton>
            </PageContainer>
        }/>
  );
};

export default AboutCEO;
