import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar'; 
import { useNavigate,useLocation } from 'react-router-dom';
import { useDeviceId } from '../../data/DeviceIdContext';
import { useArticles } from '../../data/ArticlesContext';
import LoaderView from '../../components/loader_view'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PAYSIKA_LINK } from '../../data/Constants';
import { motion } from 'framer-motion';
import TitleView from '../../components/title';
import FooterLinkPaysika from '../../components/footer_link';

const CardViewContainer = styled.div` 
  position: relative;
  height: 350px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
 
 
const MobileContainer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  /* Taille par défaut pour ordinateur */
  max-width: 428px; /* Adjuster cette valeur si nécessaire */

  /* Pour les écrans plus petits (téléphones et tablettes) */
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

 

const Description = styled.p`
  font-size: 18px;
  color: #888;
`;
const SubDescription = styled.p`
  font-size: 14px;
  color: #888;
`;

 

const CardTexts = styled.p`
  font-size: 18px;
  color: ${({ color }) => color || '#3A75C4'};
  text-align: center;
  margin: 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// const Cards = styled.div`
//   background-color: ${({ color }) => color || '#f8f8f8'};
//   border-radius: 20px;
//   padding: 16px;
//   margin: 1px -8px; 
//   display: flex;
//   flex-direction: center;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   position: absolute;
//   width: 90%;
//   height: 75%;
//   left: 3%;
//   right: 4%;
// `;

const Cards = styled.div`
  background-color: ${({ color }) => color || '#f8f8f8'};
  border-radius: 20px;
  padding: 16px;
  margin: 1px -8px; 
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  width: 90%;
  height: 85%;
  left: 3%;
  right: 4%;
  transition: transform 0.6s, opacity 0.5s;
  &.swipe-right {
    transform: translateX(100vw) rotate(10deg);
    opacity: 0;
  }

  &.swipe-left {
    transform: translateX(-100vw) rotate(-10deg);
    opacity: 0;
  }
`;

 
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

const OptionButton = styled.button`
  background-color: ${({ color }) => color || '#ccc'};
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #34a853;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: spin 1s linear infinite;
  margin-top: 20px; 
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
 

const Loaders = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #34a853;
  width: 70px;
  height: 70px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Liste des couleurs représentant le drapeau gabonais
const COLORS = ['#009639', '#FFD700', '#002395']; // Vert, Jaune, Bleu
 

// Function to get the color based on the index
const getCardColor = (index) => {
  return COLORS[index % COLORS.length];
};

function MatchFast({limit}) {
  const deviceId = useDeviceId();
  const navigate = useNavigate();
  const location = useLocation();
  const { articles,fetchArticlesOnly, loading } = useArticles();
  const [canNext, setCanNext] = useState(false);

  const showErrorNotification = () => {
    toast.error("Veuillez patiente que les articles s'affichent.");
  };

  const showNotification = () => {
    toast.info("Merci d'avoir jouer le jeu!.");
    toast.success("Merci d'avoir jouer le jeu!.");
  };

  useEffect(() => {
    fetchArticlesOnly();
  }, []);
  


const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
const [userVotes, setUserVotes] = useState([]);
const [swipeClass, setSwipeClass] = useState('');

useEffect(() => {
  if (currentArticleIndex === articles.length && canNext) {
       navigate('/app/score');
  }
}, [currentArticleIndex, articles.length, navigate]);

const handleSwipe = (direction, opinion) => {
  // console.log('handleSwipe',opinion);
    if (articles.length >= 1) {
  // console.log('length',articles.length);

     const vote = articles.map(article => ({
      user_id: 0,
      article_id: article.id,
      opinion: opinion,
    }));

    console.log('vote : ',JSON.stringify(vote));

    setSwipeClass(`swipe-${direction}`);
    setUserVotes((prevVotes) => [...prevVotes, vote]); 
    setTimeout(() => {
      setSwipeClass('');
      setCurrentArticleIndex((prevIndex) => articles.length);
      setCanNext(true);
    }, 500);
    
    // Enregistre dans localStorage une fois que tous les articles sont votés
    localStorage.setItem('userVotes', JSON.stringify(vote));
   
  } else {
  console.log('showErrorNotification',opinion);

    showErrorNotification();
  }
   
};

 useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      
      const userConfirmed = window.confirm("Voulez-vous vraiment arrêter le match ?");
      
      if (userConfirmed) {
        // Si l'utilisateur confirme, revenir en arrière
        navigate('/app');
      } else {
        // Sinon, reste sur la même page
        // window.history.pushState(null, '', window.location.href);
      }
    };

    // Empêche le retour immédiat en modifiant l'historique
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    // Nettoyage de l'écouteur pour éviter des fuites de mémoire
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return  loading ? <><MobileContainer><LoaderView /></MobileContainer></> :  (
     <PageWrapperView 
      link={'/app'}
      view={
      <MobileContainer>
        <Toolbar/>
        
        <Description>Voter sans lire</Description>
        <SubDescription>Pour ceux qui ont déjà lu la nouvelle constitution</SubDescription>
         <br/>
       
        <CardViewContainer>
        <Cards
            key={1}
            color={'#CFCFCF4D'}
            index={1}
            offset={1 * 15} 
            scale={1 * 0.05}
            zIndex={1}
            className={ ''}>
              <CardTexts color={'#3A75C4'}>
                La nouvelle constitution est entre vos mains. Après avoir pris connaissance de son contenu, êtes-vous pour, contre, ou neutre ? 
                Votre voix est essentielle pour construire le Gabon de demain.
                Exprimez-vous et participez à cet acte démocratique qui façonne l’avenir de notre nation.
              </CardTexts>
        </Cards>  
           
        </CardViewContainer> 

        <p>Faites votre choix ci-dessous</p>
            <ButtonContainer>
              <OptionButton onClick={() => handleSwipe('left','no')} color="#E53935">👎 Non</OptionButton>
              <OptionButton onClick={() => handleSwipe('top','neutral')} color="#888">😊 Neutre</OptionButton>
              <OptionButton onClick={() => handleSwipe('right','yes')} color="#4CAF50">👍 Oui</OptionButton>
            </ButtonContainer>
        <br/>

        
        <FooterLinkPaysika/>
        
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </MobileContainer>
    }/>
  );
}

export default MatchFast;
