import React from 'react';
import styled from 'styled-components';
import { Box, Typography,IconButton, Button, Grid, Paper } from '@mui/material';
import { ArrowForward, HelpOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 16px',
  marginBottom: '30px',
});

const Logo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const LogoText = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#1d71b8',
});

function Toolbar() {
  //{ view,link }
  return (
      <Header>
        <Logo>
          <img src="/assets/flag/flag.png" alt="Flag" style={{ height: 28, marginRight: 6 }} />
          <LogoText>Référendum '24 ⭐</LogoText>
        </Logo>
        <IconButton>
           <Link to={'/app/a-propos'} style={{ textDecoration: 'none', color: 'inherit' }}>
            <HelpOutline />
          </Link>
        </IconButton>
      </Header>
  );
}

export default Toolbar;
