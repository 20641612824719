 
import {Title,Container} from '../shared/shared.js'
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from '../../components/toolbar';
import PageWrapperView from '../../components/wrapper_page';
import { Link } from 'react-router-dom';
import CardArticle from '../../components/card_article';
import TitleView from '../../components/title.js';
import ContainerView from '../../components/container.js';
import SubTitleView from '../../components/subtitle.js';
import { ArticlesProvider } from '../../data/ArticlesContext.js';
import { motion } from 'framer-motion';
import { ScoreProvider } from '../../data/ScoreContext.js';
// import { UserProvider } from '../../data/UserContext.js';

import FooterLinkPaysika from '../../components/footer_link';

 


const Home = () => {
   const user = JSON.parse(localStorage.getItem('user')) || {};
   const isNotEmpty = Object.keys(user).length > 0;
   const navigate = useNavigate();
    const [cards, setCards] = useState([
      { id: 1, size :1, title: 'Voter la constitution', text: 'Ceci s\'adresse aux personnes ayant déjà lu la constitution.', color: '#3A75C4',bg:'#3A75C44D' },
      { id: 2, size :1, title: 'Lire et Voter la constitiuon', text: 'Lisez chaque article et donnez votre avis afin de visualiser votre score à la fin.', color: '#3A75C4' ,bg:'#FCD1164D'},
      // Votez chaque article et déterminez votre score de match avec la nouvelle constitution
      { id: 3, size :1, title: 'Voir les résultats globaux', text: 'Voir les résultats globaux des autres votants', color: '#009E60',bg:'#EBEBEB' },
    ]);

      useEffect(() => {

        console.log('user : '+JSON.stringify(user));
        // Fonction pour bloquer le retour en arrière
        const handlePopState = (event) => {
          // Utilisez navigate pour rester sur la même page
          navigate('/app');
        };

        // Ajoute un état à l'historique pour bloquer le retour
        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handlePopState);

        // Nettoyage de l'événement lorsque le composant est démonté
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [navigate]);

  return (
    <ScoreProvider>
     <ArticlesProvider>
      <PageWrapperView
          link={'/app'}
          view={
            <div>
              <ContainerView 
                view={
                  <>
                    <Toolbar/>
                    <TitleView title={'Explorer les articles'}/>
                    <SubTitleView title={'Vérifiez si vous matchez avec la constitution et visualisez votre score !'}/>
                    {/* Vérifie ton match avec la constitution en quelques étapes */}
                      {cards.map((card, index) => (
                          <CardArticle index={index} card={card} link={index == 0?`match-rapide`:index == 1?`articles`:isNotEmpty?'score-global':'contact'}>
                            {/* /${card.title} */}
                          </CardArticle>
                      ))}  
                      <FooterLinkPaysika/>
                      <br/>
                  </>
              }>
              </ContainerView>
            </div>
            }
        />
      </ArticlesProvider>
      </ScoreProvider>

  );
};

export default Home;
