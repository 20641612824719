import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Toolbar from '../../components/toolbar';
import PageWrapperView from '../../components/wrapper_page';
import TitleView from '../../components/title';
import SubTitleView from '../../components/subtitle';
import ContainerView from '../../components/container';
import Suggestions from '../../components/suggestions';
import { useDeviceId } from '../../data/DeviceIdContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VoteProgressBarHorizontal from '../../components/progress_var_horizontal';
import FooterLinkPaysika from '../../components/footer_link';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Enregistrer les composants utilisés pour que Chart.js fonctionne correctement
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


 
const Message = styled.div`
  margin-top: 1px;
  text-align: center;
  font-size: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImagePlaceholder = styled.img`
  width: 140px;
  height: 140px;
  align-items: center;
  background-color: #e0e0e0;
  margin: 20px 0;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

 

function MatchEnd() {
  const [isLoading, setIsLoading] = useState(false);
  const deviceId = useDeviceId();
  const navigate = useNavigate();
  const [error, setError] = useState('');
 
  const [stats, setStats] = useState({ oui: 0, non: 0, neutre: 0 });
  const [winner, setWinner] = useState('');

  const showErrorNotification = () => {
    toast.error("Une erreur s'est produite, veuillez réessayer.");
  };

  const showNotification = () => {
    // toast.info("Merci d'avoir jouer le jeu!.");
    toast.success("Merci d'avoir joué le jeu ! Votre avis compte, et nous l'avons enregistré.");
  };


  const handleSubmit = async (data) => {
    console.log('stat handleSubmit : '+JSON.stringify(data));
    setIsLoading(true);

    if (!navigator.onLine) {
      setError("Vous n'avez pas de connexion internet.");
      setIsLoading(false);
      return;
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'Device-ID': deviceId
    }

    try {
      const response = await axios.post('/vote', data,{headers: headers});
      if (response.status === 201) {
        // navigate('/app/score');
        console.log('succès vote');
      } else {
        setError('Une erreur est survenue. Veuillez réessayer.');
        console.log('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (err) {
      setError('Erreur de serveur. Veuillez réessayer plus tard.');
      console.log('Erreur de serveur. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
    console.log('end handleSubmit');
  };

  useEffect(() => {
    showNotification();
    // Récupérer les votes depuis le localStorage
    const storedVotes = JSON.parse(localStorage.getItem('userVotes')) || [];

    console.log('storedVotes : '+JSON.stringify(storedVotes));
    // Compter les votes par opinion
    const voteCounts = { oui: 0, non: 0, neutre: 0 };
    storedVotes.forEach(vote => {
      if (vote.opinion === 'yes') voteCounts.oui++;
      if (vote.opinion === 'no') voteCounts.non++;
      if (vote.opinion === 'neutral') voteCounts.neutre++;
    });

    // Définir les statistiques de votes
    setStats(voteCounts);

    // Déterminer l'opinion majoritaire
    const highestVote = Math.max(voteCounts.oui, voteCounts.non, voteCounts.neutre);
    const majorOpinion = 
      highestVote === voteCounts.oui ? 'yes' : 
      highestVote === voteCounts.non ? 'no' : 'neutral';

    setWinner(majorOpinion);

    handleSubmit(storedVotes);

  }, []);

  // if (isLoading) return <p>Loading...</p>;

   useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      
      // Redirige vers la page d'accueil et remplace l'historique, { replace: true }
      navigate('/app');
    };

    // Ajoute un état à l'historique et écoute l'événement de retour
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    // Nettoyage de l'écouteur lorsqu'on quitte la page
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

   // Configuration des options du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Bar Chart Example',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true
      },
       x: {
            grid: {
              offset: true,
              stacked: true
            }
        }
    },
  };

  return (
    <PageWrapperView
        link={'/app'}
        view={
            <>
                <ContainerView
                view={
                    <>
                        <Toolbar/>
                            <Message>
                                <TitleView title={winner === 'yes' ?'Sur la base des questions répondus, vous matchez avec la constitution':'Sur la base des questions répondus, vous ne matchez pas avec la constitution'}/>
                                    {winner === 'yes' ? (
                                      <ImagePlaceholder src="/assets/image/good.png" alt="Logo" />
                                    ) : (
                                      <ImagePlaceholder src="/assets/image/lost.png" alt="Logo" />
                                    )}
                                <p>Refaites le test pour avoir un avis plus complet </p>
                            </Message>
                             <Bar data={ {
                                    labels: [`👍 Oui (${stats.oui})`, `😐 Neutre (${stats.neutre??0})`, `👎 Non (${stats.non??0})`], // Exemple de labels
                                    datasets: [
                                      {
                                        label: '',//Majorité en-tête
                                        data: [stats.oui, stats.neutre??0, stats.non??0],
                                        backgroundColor: [
                                          '#009639',
                                          '#FFD700',
                                          '#002395',
                                        ],
                                        borderColor: [
                                          'rgba(0, 150, 57, 0.2)',
                                          'rgba(255, 215, 0, 0.2)',
                                          'rgba(0, 35, 149, 0.2)',
                                        ],
                                        borderWidth: 1,
                                      },
                                    ],
                                  }} 
                                  
                                  options={options}>


                                  </Bar>
                            {/* <VoteProgressBarHorizontal yesVotes={stats.oui} noVotes={stats.non} neutralVotes={stats.neutre} />                             */}
                            <br/><br/>
                            <Button onClick={() => {
                               navigate('/app/articles')}}>👍 Répondre à plus de questions </Button>

                            {/* <ProgressBarContainer> Répondre plus de questions Lisez  plus d’articles
                              <ProgressSegment style={{ width: `${yesPercentage}%` }} />
                              <ProgressSegment style={{ width: `${noPercentage}%` }} />
                              <ProgressSegment style={{ width: `${neutralPercentage}%` }} />
                            </ProgressBarContainer> */}
   
                            <Suggestions/>
                            <br/>
                            <br/>
                            <br/>
                            {/* <br/>
                              <FooterLinkPaysika/>
                            <br/> */}
                            <ToastContainer 
                              position="top-right"
                              autoClose={7000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                            />

                    </>
                } 
                />
                    
            </>
        }
    />
   
  );
}

export default MatchEnd;
