import styled from 'styled-components';

// Composants stylisés pour la mise en page
const Container = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
 
const Title = styled.h1`
  font-size: 24px;
  color: #2b2d42;
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #8d99ae;
`;


const GreenTextButton = styled.div`
  background-color: #e0e7e9;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;