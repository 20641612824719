import { RotatingLines } from "react-loader-spinner";
import styled from 'styled-components';


const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); /* Optionnel pour un fond semi-transparent */
  z-index: 9999; /* Assure que le loader est au-dessus des autres éléments */
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #34a853;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
 


function LoaderView() {
  return (
     <LoaderContainer>
    <Loader />
  </LoaderContainer>
    // <RotatingLines
    //   strokeColor="grey"
    //   strokeWidth="5"
    //   animationDuration="0.75"
    //   width="96"
    //   visible={true}
    // />
  )
}

export default LoaderView;
