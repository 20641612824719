import styled from 'styled-components';
 
const Title = styled.h1`
  font-size: 24px;
  color: #2b2d42;
  display: flex;
  justify-content: space-between;
`;

function TitleView({ title }) {
  return (
      <Title>
        {title}
      </Title>
  );
}
export default TitleView;
