import React, { createContext, useContext, useState, useEffect } from 'react';

const DeviceIdContext = createContext();

export const DeviceIdProvider = ({ children }) => {
  const [deviceId, setDeviceId] = useState(null);

  useEffect(() => {
    let storedDeviceId = localStorage.getItem('deviceId');
    if (!storedDeviceId) {
      // Génération d'un identifiant unique basé sur plusieurs éléments concaténés
        const userAgent = navigator.userAgent.replace(/[\s\-();.,/]+/g, ''); // Remplacer les espaces pour éviter les erreurs
        const date = new Date().getTime(); // Timestamp pour une précision temporelle
        storedDeviceId = `device${userAgent}${date}`;
      // Sauvegarde dans le localStorage
      localStorage.setItem('deviceId', storedDeviceId);
    }
    setDeviceId(storedDeviceId);
  }, []);

  return (
    <DeviceIdContext.Provider value={deviceId}>
      {children}
    </DeviceIdContext.Provider>
  );
};

export const useDeviceId = () => useContext(DeviceIdContext);
