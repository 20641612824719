import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'; 
import { ArrowForward } from '@mui/icons-material';
import { motion } from 'framer-motion';
  

// Composants stylisés pour la mise en page
const Card = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  border-radius: 15px;
  height: 110px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 86%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color:${({ color }) => color};
  font-weight: bold;
  margin: 0;
`;

const CardText = styled.p`
  font-size: 14px;
  color: #000000; 
`;


function CardArticle({ card,index,link }) {
  const dataToSend = "Hello from Page A";
  return card.size == 1?(
      <>
       <Card key={index} color={card.bg}>
            <Link to={link} state={{ data: card.size }}  style={{ textDecoration: 'none', color: 'inherit' }}>
                <CardTitle color={card.color}>{card.title}</CardTitle>
                <CardText color={card.bg}>{card.text}</CardText>
                <ArrowForward style={{ color: '#3A75C4', alignSelf: 'flex-end',padding: '8px 12px' }}/> 
            </Link>
        </Card>
      </>
  ): (
    //  state={{card:card.title}}
      <>
       <div className="flex flex-1 flex-col items-center sm:items-start py-32 px-24 md:px-32">
      <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0.4 } }}
            >
       <Card key={index} color={card.bg}>
            <Link to={link} state={{ data: card.size }}  style={{ textDecoration: 'none', color: 'inherit' }}>
                <CardTitle color={card.color}>{card.title}</CardTitle>
                <CardText color={card.bg}>{card.text}</CardText>
                <ArrowForward style={{ color: '#3A75C4', alignSelf: 'flex-end',padding: '8px 8px' }}/> 
            </Link>
        </Card>
        </motion.div>
        </div>
      </>
  );
}

export default CardArticle;
