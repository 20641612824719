import styled from 'styled-components';
 
const Subtitle = styled.p`
  font-size: 14px;
  color: #8d99ae;
`;

function SubTitleView({ title }) {
  return (
      <Subtitle>
        {title}
      </Subtitle>
  );
}
export default SubTitleView;
