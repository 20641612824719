import React from 'react';
import { Box, Typography, Button, IconButton, Grid, Paper } from '@mui/material';
import { ArrowForward, HelpOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import GetStartedButton from '../../components/Button/get_start_btn';
import CardContainer from '../../components/PropositionCard/card_container';


const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f7f7f7',
  minHeight: '90vh',
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 16px',
  marginBottom: '30px',
});

const Logo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const LogoText = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#1d71b8',
});

const Card = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  borderRadius: '10px',
  margin: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '180px',
  cursor: 'pointer',
});

const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px 16px',
  backgroundColor: '#4caf50',
  paddingLeft:40, 
  position: 'fixed',
  bottom: 0,
});

const Projets = () => {
  return (
    <Container>
      <Header>
        <Logo>
          <img src="/assets/flag/flag.png" alt="Flag" style={{ height: 28, marginRight: 6 }} />
          <LogoText>Référendum '24 ⭐</LogoText>
        </Logo>
        <IconButton>
          <HelpOutline />
        </IconButton>
      </Header>
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>Projets constitutionnels</Typography>
        <Typography variant="body1" color="textSecondary">
          Participer activement aux discussions sur des projets constitutionnels et référendaires en votant article par article.
        </Typography>
      </Box>
        {/* <Grid item>
          <Card elevation={3} onClick={() => alert("Explorer les articles")}>
            <Typography variant="h6" color="primary">Explorer les articles</Typography>
            <ArrowForward />
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={3} onClick={() => alert("Voir les résultats")}>
            <Typography variant="h6" style={{ color: '#388e3c' }}>Voir les résultats</Typography>
            <ArrowForward />
          </Card>
        </Grid> */}
  
      <CardContainer/>
      <Typography variant="caption" color="textSecondary" style={{ marginTop: 40 }}>
        Cette plateforme vous est proposée par: <strong>PaySika</strong>
      </Typography>
      <Footer>
        {/* <Button startIcon={<img src="/assets/image/layers.png" alt="Home" style={{ height: 20 }} />} style={{ color: '#ffffff' }}>
        </Button> */}
         <GetStartedButton
            to="/app"
            title="Accueille" 
            imageUrl="/assets/image/layers.png" 
          />
        <IconButton>
          <img src="/assets/image/filter.png"  alt="Settings" style={{ height: 35,marginRight:30 }} />
        </IconButton>
      </Footer>
    </Container>
  );
};

export default Projets;
