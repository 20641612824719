import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { PAYSIKA_LINK } from '../data/Constants';


const SuggestionsContainer = styled.div`
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  margin-top: 20px;
`;

const SuggestionTitle = styled.p`
  color: #707070;
  font-size: 14px;
  margin-bottom: 10px;
`;

const SuggestionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuggestionItem = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1a0dab;
  margin: 5px 0;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #1a0dab;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  margin-right: 10px;
`;

const Suggestions = ({resultat}) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user')) || {};
  const isNotEmpty = Object.keys(user).length > 0;
  useEffect(() => {
      console.log('user : '+JSON.stringify(user));
    }, []);

  const handleShareWithImage = async () => {
  const imagePath = '/assets/image/share.png';

  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    const file = new File([blob], 'image.png', { type: 'image/jpeg' });

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      await navigator.share({
        title: 'Référendum‘24',
        text: "J'ai pris part au vote sur Référendum 2024, et maintenant c'est à toi ! Viens t'impliquer et fais entendre ta voix",
        files: [file],
        url: 'https://www.referendum2024.ga/',
      });
      console.log('Partage réussi !');
    } else {
      alert("Le partage de fichiers n'est pas pris en charge sur cet appareil.");
    }
  } catch (error) {
    console.error("Erreur lors de la récupération ou du partage de l'image :", error);
  }
};

  let content;

  if (isNotEmpty) {
    content = <p>Voir les résultats globaux !</p>;
  } else {
    content = <p>Sauvegarder vos résultats ?.</p>;
  }

  return (resultat ? <><SuggestionsContainer>
      <SuggestionList>
        <Link onClick={handleShareWithImage}  style={{ textDecoration: 'none', color: 'inherit' }}>
           <SuggestionItem target="_blank" rel="noopener noreferrer" >
              <Icon />
                Partager a vos amis
          </SuggestionItem>
        </Link>
      </SuggestionList>
    </SuggestionsContainer></>
  :
    <><SuggestionsContainer>
      <SuggestionTitle>Vous pouvez aussi:</SuggestionTitle>
      <SuggestionList>
          <Link onClick={handleShareWithImage}  style={{ textDecoration: 'none', color: 'inherit' }}>
           <SuggestionItem target="_blank" rel="noopener noreferrer" >
              <Icon />
                Partager a vos amis
          </SuggestionItem>
        </Link>
        <SuggestionItem target="_blank" rel="noopener noreferrer" href={PAYSIKA_LINK}>
          <Icon />
             Obtenir gratuitement votre carte bancaire Visa PaySika
        </SuggestionItem>
         <Link onClick={() => {  if (isNotEmpty) {
                                 navigate('/app/score-global')
                              }else{
                                navigate('/app/contact')
                              }} }  style={{ textDecoration: 'none', color: 'inherit' }}>
        <SuggestionItem >
          <Icon />
             {content}
        </SuggestionItem>
        </Link>
      </SuggestionList>
    </SuggestionsContainer></>
  );
};

export default Suggestions;
