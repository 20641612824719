import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ProgressBar = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
`;

const ProgressSegment = styled.div`
  height: 100%;
  &:nth-child(1) { background-color: #4caf50; } /* Couleur pour OUI */
  &:nth-child(2) { background-color: #E53935; } /* Couleur pour NON'#FFD700', '' */
  &:nth-child(3) { background-color: #888; } /* Couleur pour NEUTRE#002395 */
`;

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
`;

const VoteProgressBarHorizontal = ({ yesVotes, noVotes, neutralVotes }) => {
  const totalVotes = yesVotes + noVotes + neutralVotes;

  const yesPercentage = ((yesVotes / totalVotes) * 100) || 0;
  const noPercentage = ((noVotes / totalVotes) * 100) || 0;
  const neutralPercentage = ((neutralVotes / totalVotes) * 100) || 0;

  return (
    <ProgressBarContainer>
      <ProgressBar>
        <ProgressSegment style={{ width: `${yesPercentage}%` }} />
        <ProgressSegment style={{ width: `${noPercentage}%` }} />
        <ProgressSegment style={{ width: `${neutralPercentage}%` }} />
      </ProgressBar>
      <LabelContainer>
        <Label>
          <span>OUI 👍</span>
          <span>{yesVotes} votes</span>
        </Label>
        <Label>
          <span>NEUTRE 👎</span>
          <span>{neutralVotes} votes</span>
        </Label>
        <Label>
          <span>NON 😞</span>
          <span>{noVotes} votes</span>
        </Label>
      </LabelContainer>
    </ProgressBarContainer>
  );
};

export default VoteProgressBarHorizontal;
