import React from 'react';
import styled from 'styled-components';

const ProgressBarsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px; /* Espace entre chaque barre */
  height: 200px; /* Hauteur de la zone de barres */
  width: 100%;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
`;

const Label = styled.div`
  margin-top: 8px;
  font-weight: bold;
  text-align: center;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #E53935; /* Couleur de fond pour la barre */
  border-radius: 8px 8px 0 0; 
  height: 100%; /* Remplir toute la hauteur disponible */
  position: relative;
`;

const ProgressSegment = styled.div`
  width: 100%;
  height: ${({ percentage }) => percentage}%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${({ color }) => color || "#E53935"}; /* Couleur par défaut */
`;

const VoteProgressBarVertical = ({ yesVotes, noVotes, neutralVotes }) => {
  const totalVotes = yesVotes + noVotes + neutralVotes;

  const yesPercentage = ((yesVotes / totalVotes) * 100) || 0;
  const noPercentage = ((noVotes / totalVotes) * 100) || 0;
  const neutralPercentage = ((neutralVotes / totalVotes) * 100) || 0;

  return (
    <ProgressBarsContainer>
      {/* Barre verticale pour "OUI" */}
      <ProgressBarWrapper>
        <ProgressBar>
          <ProgressSegment
            color="#4caf50"
            percentage={yesPercentage}
          />
        </ProgressBar>
        <Label>OUI 😊 {yesPercentage.toFixed(1)}%</Label>
      </ProgressBarWrapper>
      
      {/* Barre verticale pour "NON" */}
      <ProgressBarWrapper>
        <ProgressBar>
          <ProgressSegment
            color="#f44336"
            percentage={noPercentage}
          />
        </ProgressBar>
        <Label>NON 😞 {noPercentage.toFixed(1)}%</Label>
      </ProgressBarWrapper>
      
      {/* Barre verticale pour "NEUTRE" */}
      <ProgressBarWrapper>
        <ProgressBar>
          <ProgressSegment
            color="#ff9800"
            percentage={neutralPercentage}
          />
        </ProgressBar>
        <Label>NEUTRE 😐 {neutralPercentage.toFixed(1)}%</Label>
      </ProgressBarWrapper>
    </ProgressBarsContainer>
  );
};

export default VoteProgressBarVertical;




/*
import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
`;

const ProgressSegment = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
`;

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`;

const Label = styled.div`
  text-align: center;
  font-weight: bold;
`;

const VoteProgressBarHorizontal = ({ yesVotes, noVotes, neutralVotes }) => {
  const totalVotes = yesVotes + noVotes + neutralVotes;

  const yesPercentage = ((yesVotes / totalVotes) * 100) || 0;
  const noPercentage = ((noVotes / totalVotes) * 100) || 0;
  const neutralPercentage = ((neutralVotes / totalVotes) * 100) || 0;

  return (
    <ProgressBarContainer>
      <ProgressBar>
        <ProgressSegment
          style={{ backgroundColor: "#4caf50" }}
          percentage={yesPercentage}
        />
        <ProgressSegment
          style={{ backgroundColor: "#f44336" }}
          percentage={noPercentage}
        />
        <ProgressSegment
          style={{ backgroundColor: "#ff9800" }}
          percentage={neutralPercentage}
        />
      </ProgressBar>
      
      <LabelsContainer>
        <Label>OUI 😊 {yesPercentage.toFixed(1)}%</Label>
        <Label>NON 😞 {noPercentage.toFixed(1)}%</Label>
        <Label>NEUTRE 😐 {neutralPercentage.toFixed(1)}%</Label>
      </LabelsContainer>
    </ProgressBarContainer>
  );
};

export default VoteProgressBarHorizontal;
 */