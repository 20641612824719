import React, { useState } from 'react';
import styled from 'styled-components';
import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar';
import { useParams } from 'react-router-dom';
import ContainerView from '../../components/container';
import TitleView from '../../components/title';
import SubTitleView from '../../components/subtitle';
import CardArticle from '../../components/card_article';
import FooterLinkPaysika from '../../components/footer_link';

 
 
function Articles() {
   const [cards, setCards] = useState([
      { id: 1, size : 20, title: 'Flash match', text: 'Voici 20 articles aléatoires pour avoir un avis sur la constitution.', color: '#3A75C4',bg:'#3A75C44D' },
      { id: 2, size : 60, title: 'Short match', text: 'Voici 60 articles aléatoires pour avoir un avis sur la constitution.', color: '#3A75C4' ,bg:'#FCD1164D'},
      { id: 3, size : 90, title: 'Medium Match', text: 'Voici 90 articles aléatoires pour avoir un avis sur la constitution.', color: '#3A75C4',bg:'#009E604D' },
      { id: 4, size : 172, title: 'Full Match', text: 'Voici 172 articles aléatoires pour avoir un avis sur la constitution.', color: '#3A75C4',bg:'#EBEBEB' },
    ]);
  const { label } = useParams();
  return (
     <PageWrapperView
        link={'/app'}
        view={
          <div>
            <ContainerView 
              view={
                <>
                  <Toolbar/>
                  <TitleView title={'Lire et voter'}/>
                  <SubTitleView title={'Vérifiez si vous matchez avec la constitution et visualisez votre score !'}/>
                  {cards.map((card, index) => (
                      <CardArticle index={index} card={card} link={`/app/match`}>
                        {/* /${card.title} */}
                      </CardArticle>
                    ))}  
                    <br/>
                    <FooterLinkPaysika/>
                    <br/>
                </>
            }>
            </ContainerView>
          </div>
          }
      />
    
    
  );
}

export default Articles;


 

// import React from 'react';
// import styled from 'styled-components';
// import PageWrapperView from '../wrapper_page';

// // Composants stylisés pour la mise en page
// const Container = styled.div`
//   background-color: #f5f5f5;
//   padding: 20px;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   padding: 10px;
// `;

// const Logo = styled.img`
//   height: 30px;
// `;

// const Title = styled.h1`
//   font-size: 24px;
//   color: #2b2d42;
// `;

// const Subtitle = styled.p`
//   font-size: 14px;
//   color: #8d99ae;
// `;

// const Card = styled.div`
//   background-color: white;
//   border-radius: 15px;
//   padding: 20px;
//   margin: 10px 0;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   width: 90%;
// `;

// const CardTitle = styled.h2`
//   font-size: 18px;
//   color: #2b2d42;
//   margin: 0;
// `;

// const CardText = styled.p`
//   font-size: 14px;
//   color: #8d99ae;
// `;

// const GreenTextButton = styled.div`
//   background-color: #e0e7e9;
//   border-radius: 15px;
//   padding: 10px;
//   margin: 10px 0;
//   width: 90%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const ButtonText = styled.p`
//   font-size: 16px;
//   color: #007f00;
//   font-weight: bold;
// `;

// const ArrowIcon = styled.span`
//   font-size: 20px;
//   color: #007f00;
// `;

// const BottomBar = styled.div`
//   background-color: #4caf50;
//   width: 100%;
//   padding: 15px;
//   position: fixed;
//   bottom: 0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const BottomBarButton = styled.button`
//   background-color: #4caf50;
//   color: white;
//   border: none;
//   font-size: 16px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
// `;

// const BottomIcon = styled.span`
//   font-size: 24px;
//   margin-right: 5px;
// `;

// const PageWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #f0f0f0; /* Fond gris */
//   min-height: 100vh; /* Occupe toute la hauteur de l'écran */
// `;

// const Articles = () => {
//   return (
//     <PageWrapper>
//         <Container>
//         <Header>
//             <Logo src="path_to_logo.png" alt="Logo" />
//             <span>❓</span>
//         </Header>

//         <Title>Référendum '24 🌟</Title>
//         <Subtitle>Vérifie ton match avec la constitution en quelques étapes</Subtitle>

//         <Card>
//             <CardTitle>Voter</CardTitle>
//             <CardText>Pour ce qui on déjà lu la nouvelle constitution</CardText>
//         </Card>

//         <Card>
//             <CardTitle>Lire et voter</CardTitle>
//             <CardText>
//             Votez chaque article et déterminez votre score de match avec la nouvelle constitution
//             </CardText>
//         </Card>

//         <GreenTextButton>
//             <ButtonText>Voir les résultats</ButtonText>
//             <ArrowIcon>➡️</ArrowIcon>
//         </GreenTextButton>

//         <BottomBar>
//             <BottomBarButton>
//             <BottomIcon>🏠</BottomIcon>
//             Accueil
//             </BottomBarButton>
//             <BottomIcon>☰</BottomIcon>
//         </BottomBar>
//         </Container>
//    </PageWrapper>
//   );
// };

// export default Articles;
