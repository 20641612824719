import React from 'react';
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Route, Routes,createBrowserRouter,
  RouterProvider, } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals'; 
import ErrorPage from './routes/404/error_page.js';
import Projets from './routes/projets/projets.js';
import Splash from './routes/pages/splash.js';
import Home from './routes/pages/home.js';
import Articles from './routes/pages/articles.js';
import Layout from './routes/pages/layout.js';
import Match from './routes/pages/match.js';
import Tinder from './routes/match/tinder.js';
import FastMatch from './routes/pages/fast_match.js';
import MatchEnd from './routes/pages/match_end.js';
import Resultat from './routes/pages/resultat.js';
import Infos from './routes/pages/infos.js';

import { DeviceIdProvider } from './data/DeviceIdContext.js';
import { ArticlesProvider } from './data/ArticlesContext.js';
import { ScoreProvider } from './data/ScoreContext.js';

import Forms from './routes/pages/forms.js';
import axios from 'axios';
import { API_URL } from './data/Constants.js';
import MatchFast from './routes/pages/fast_match.js';
import Onboarding from './routes/pages/onboarding.js';



const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
if (window.matchMedia("(display-mode: standalone)").matches) {
    container?.classList.add("pwa");
}
/**
 * Axios HTTP Request defaults
 */ 
axios.defaults.baseURL = API_URL; 
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json'; 
// axios.defaults.headers.common['Device-ID'] = 

root.render( 
    <React.StrictMode>
        {/* <RouterProvider router={router} /> */}
         <DeviceIdProvider>
          <ArticlesProvider>
            <ScoreProvider>
              <BrowserRouter>
                <Routes>
                <Route path="/" index element={<Splash/>}/>
                <Route path="/a-propos" element={<Onboarding/>}/>
                <Route path="/app" element={<Home/>}/>
                <Route path="/app/articles" element={<Articles/>}/>
                <Route path="/app/match" element={<Match/>}/>
                <Route path="/app/match-rapide" element={<MatchFast/>}/>
                <Route path="/app/score" element={<MatchEnd/>}/>
                <Route path="/app/score-global" element={<Resultat/>}/>
                <Route path="/app/a-propos" element={<Infos/>}/>
                <Route path="/app/contact" element={<Forms />}/>
                <Route path="/*" element={<ErrorPage/>}/>
              </Routes>
            </BrowserRouter>
            </ScoreProvider>
          </ArticlesProvider>
        </DeviceIdProvider>,
       
    </React.StrictMode>
); 
reportWebVitals();
