import React from 'react';
import styled from 'styled-components';

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px; /* Espace entre chaque barre */
  height: 300px; /* Hauteur du conteneur pour les barres */
  width: 100%;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px; /* Largeur fixe pour chaque barre */
`;

const Label = styled.div`
  margin-top: 8px;
  font-weight: bold;
  text-align: center;
`;

const Bar = styled.div`
  width: 100%;
  background-color: #009639; /* Couleur par défaut */
  border-radius: 4px 4px 0 0;
  height: ${({ height }) => height}%;
`;
// '#009639', '#FFD700', '#002395'
const PercentageLabel = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

const VoteBarChart = ({ yesVotes, noVotes, neutralVotes }) => {
  const totalVotes = yesVotes + noVotes + neutralVotes;

  const yesPercentage = ((yesVotes / totalVotes) * 100) || 0;
  const noPercentage = ((noVotes / totalVotes) * 100) || 0;
  const neutralPercentage = ((neutralVotes / totalVotes) * 100) || 0;

  return (
    <ChartContainer>
      {/* Barre verticale pour "OUI" */}
      <BarWrapper>
        <PercentageLabel>{yesVotes}</PercentageLabel>
        <Bar color="#4caf50" height={yesPercentage} />
        <Label>👍 Oui</Label>
      </BarWrapper>
      
      {/* Barre verticale pour "NEUTRE" */}
      <BarWrapper>
        <PercentageLabel>{neutralVotes}</PercentageLabel>
        <Bar color="#ff9800" height={neutralPercentage} />
        <Label>😐 Neutre</Label>
      </BarWrapper>
      
      {/* Barre verticale pour "NON" */}
      <BarWrapper>
        <PercentageLabel>{noVotes}</PercentageLabel>
        <Bar color="#f44336" height={noPercentage} />
        <Label>👎 Non</Label>
      </BarWrapper>
    </ChartContainer>
  );
};

export default VoteBarChart;
