// GetStartedButton.js
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
 

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 10px;
  margin-left: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const IconWrapper = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const ButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: #111111;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  &:hover {
    background-color: #f0f0f0;
  }
`;

function StartedButton({ to,title, color }) {
  return (
    <ToggleButton> 
        <FaArrowRight style={{ marginLeft: '8px' ,color:color }} />
        {title}
    </ToggleButton>
  );
};

export default StartedButton;
