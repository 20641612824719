import React, { useState } from 'react';
import styled from 'styled-components';
import ContainerView from '../../components/container';
import PageWrapperView from '../../components/wrapper_page';
import Toolbar from '../../components/toolbar';
import TitleView from '../../components/title';
import FooterLinkPaysika from '../../components/footer_link';
import { useNavigate,useLocation } from 'react-router-dom';
import AboutCEO from './ceo_info';
import { FaArrowRight } from 'react-icons/fa';
import StartedButton from '../../components/Button/start_button';
// import { UserProvider } from '../../data/UserContext.js';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Fond gris */
  min-height: 100vh; /* Occupe toute la hauteur de l'écran */
`;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #0077ff;
  padding-bottom: 10px;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #0077ff;
  margin: 0;
`;

const Star = styled.span`
  color: #ffda44;
  font-size: 1.5rem;
`;

const HelpIcon = styled.img`
  height: 25px;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  color: #0077ff;
  margin-top: 20px;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
`;

const Link = styled.a`
  color: #0077ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-around;
  background: #f8f8f8;
  padding: 10px 0;
  border-top: 1px solid #ddd;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  color: #0077ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #005bb5;
  }
`;

const HomeIcon = styled.span`
  margin-right: 5px;
`;

const slides = [
  "Référendum 2024, proposé par PaySika, est une plateforme innovante ouverte à tous les citoyens gabonais, avec un fort accent sur la jeunesse. Elle permet à chacun de s'impliquer activement dans les décisions qui façonneront leur avenir. En tant que compagnon numéro 1 de la jeunesse, PaySika anticipe les besoins des jeunes et répond par des innovations adaptées, les rendant plus autonomes, plus engagés et plus émancipés dans tous les aspects de leur vie – que ce soit sur le plan civique, éducatif, ou social.",
  "Référendum 2024 permet à chaque citoyen de voter article par article sur des projets de société et de suivre en temps réel les tendances des votes. Avec cette plateforme, PaySika offre à la jeunesse gabonaise un outil innovant pour s'exprimer librement et participer activement aux décisions importantes. Ce rôle s'inscrit dans la continuité des efforts de PaySika pour accompagner la jeunesse dans sa quête d’autonomie, comme l’illustre l'obtention d'une carte bancaire gratuite, qui est l’un des nombreux services innovants conçus pour les jeunes. Grâce aux services financiers offerts par PaySika, chacun peut gérer ses finances de manière plus autonome et sécurisée, soutenant ainsi l'émancipation dans tous les aspects de la vie quotidienne – qu'il s'agisse des études, de la carrière professionnelle ou de l'implication dans la société.",
  "Participez activement à la transformation de votre pays avec Référendum 2024. Ensemble, rendons la jeunesse africaine plus autonome, plus engagée, et prête à relever les défis de demain grâce à des outils innovants qui répondent à leurs besoins.",
];

const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
`;

const SlideText = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  text-align: left;
`;
// font-size: 16px;
//   color: #333;
  // margin-bottom: 40px;
const NavigationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #009639;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #006b3c;
  }
`;

const PageContainer = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: column;
  max-width: 428px; /* Largeur type pour un téléphone mobile */
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  padding:10px;
  left: 20px;
  display: flex;
  align-items: left;
  background-color: while;
  color: #111111;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  &:hover {
    background-color: while;
  }
`;

function Onboarding() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else { 
      // navigate('/app');
      toggleExpand();
    }
  };

   

  
  return (
    
     <PageWrapperView
        link={'/app'}
        isFooter={true}
        view={
          <div>
            <ContainerView 
              view={
                <>
                  <Toolbar/>
                  { expanded? <AboutCEO/>:
                    <>
                      <Container> 
                        <TitleView title={'Description de la plateforme'}/>
                        <br/> 
                         <OnboardingContainer> 
                          <SectionTitle>Partie {currentSlide +1}</SectionTitle>
                           <SlideText>{slides[currentSlide]}</SlideText>
                          {/* <Text>
                             {slides[currentSlide]}
                          </Text> */}
                          {/* <NavigationButton onClick={handleNext}>
                            {currentSlide < slides.length - 1 ? "Suivant" : "Mot du dirigeant"}
                          </NavigationButton> */}
                          <br/>
                          <br/>
                          <br/>
                           <FooterLinkPaysika/>
                        </OnboardingContainer>
                         <ToggleButton onClick={handleNext}>
                            <FaArrowRight style={{ marginLeft: '8px',marginRight: '8px' ,color:'#111111' }} />
                             {currentSlide < slides.length - 1 ? " Suivant" : " Suivant"}
                        </ToggleButton>
                      </Container>
                    </>}
             
                </>
            }>
            </ContainerView>
          </div>
          }
      />
   
  );
}

export default Onboarding;
